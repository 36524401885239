import { Component, HostListener } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "minto-app";

  constructor(private _snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  @HostListener("window:online", ["$event"])
  onOnline(event: Event): void {
    this._snackBar.open("Back To Online 😀 spidersense™", "✔️", {
      verticalPosition: "top",
      panelClass: ["success-snackbar"],
      duration: 5000,
    });
  }

  @HostListener("window:offline", ["$event"])
  onOffline(event: Event): void {
    this._snackBar.open("No Internet Detected 😕 spidersense™", "⚠️", {
      verticalPosition: "top",
      panelClass: ["alert-snackbar"],
    });
  }
}

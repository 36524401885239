export abstract class RouteConstant {
  /* Auth Module */
  static readonly AUTH = "/auth";
  static readonly LOGIN = "/auth/login";
  static readonly NONPAYMENTREDIRECT = "/auth/non-payment-redirect";

  /* Console Module */
  static readonly CONSOLE = "/console";

  /* Dashboard Module */
  static readonly DASHBOARD = "/console/diagnose";

  // // Analytic Module
  // static readonly ANALYTICS = '/console/overview';

  // Analytic Module
  static readonly ANALYTICS = "/console/machine-insights";

  /* Overview */
  static readonly OVERVIEW = "/console/overview";

  /* Monitor Module */
  static readonly MONITOR = "/console/monitor";

  static readonly ALERTS = "/console/monitor/alerts";

  /* Analytics Module */
  static readonly ANALYTICS_MODULE = "/console/analyse";

  /* Optimize Module */
  static readonly OPTIMIZE = "/console/optimize";

  /* Tickets Module */

  static readonly TICKETS = "/console/tickets";
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { RouteConstant } from "./constants";
import { CloudGuard } from "./services/guards/cloud.guard";

const routes: Routes = [
  { path: "auth", loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  {
    path: "console",
    loadChildren: () => import('./modules/console/console.module').then(m => m.ConsoleModule),
    canActivate: [CloudGuard],
  },
  { path: "", redirectTo: RouteConstant.CONSOLE, pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
